import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiService as api } from "../../services/ApiService";
import { useSession } from "../../hooks/useSession";
import { isLocal } from "../../services/env";

export const useDettaglioPrenotazionePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { batchUpdate, read } = useSession();
    const { id, posto, data, oraInizio, oraFine, dataInizio, dataFine } = state;
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if(isLocal()) console.log(state);
    }, [])

    const deleteBooking = async () => {
        setBusy(true);

        const allBookings = read("userBookings");
        const deletePromises = [];
        allBookings.forEach(booking => {
            if((booking.dataInizio == dataInizio && booking.dataFine == dataFine) || booking.numeroPrenotazione == id) {
                deletePromises.push(api.AnnullaPrenotazione({ Codice: booking.numeroPrenotazione, UserId: read("userId") }));
            }
        });
        // const deleteRes = await api.AnnullaPrenotazione({ Codice: id, UserId: read("userId") });
        const deleteRes = await Promise.all(deletePromises);

        // if (deleteRes.result) {
        if (deleteRes && deleteRes.length) {
            navigate("/soste-programmate");
        } else {
            // console.log(deleteRes.message);
        }
        batchUpdate({
            selectedDate: null,
            selectedSlot: null
        });
        setBusy(false);
    };

    const back = () => {
        navigate(-1);
    }

    const goToBookingDetailMap = (data) => {
        if(isLocal()) console.log(state);
        
        navigate("/dettaglio-prenotazione-mappa", {
          state
        });
      };

    return {
        deleteBooking,
        posto, 
        data, 
        oraInizio, 
        oraFine,
        busy, 
        back,
        goToBookingDetailMap
    };
}