import { useSession } from "./useSession";
import { useState, useEffect } from "react";
import { ApiService as api } from "../services/ApiService";
import moment from "moment";

export const useBookings = (programmate = true) => {
  const { read, update } = useSession();
  const [userBookings, setUserBookings] = useState([]);
  const [ready, setReady] = useState(false);

  const getUserBookings = async () => {
    try {
      if (programmate) {
        document.querySelector(".elenco_soste-prog").classList.add("loading");
      } else {
        document.querySelector(".elenco_soste-effet").classList.add("loading");
      }
    } catch (error) {}

    setReady(false);
    const bookingsRes = await api.ListaPrenotazioni({
      IdUtente: read("userId"),
      Data: "1977-01-01 13:36:00",
      DataFine: "2099-01-01 13:36:00",
    });

    const allOrders = bookingsRes.listaPrenotazioni || [];
    
    const futureOrders = allOrders.filter((_) => {
      if (programmate) {
        return moment(_.dataInizio) > moment().subtract(1, "days");
      } else {
        return moment(_.dataInizio) <= moment().subtract(1, "days");
      }
    });
    futureOrders.sort((a, b) => {
      return moment(a.dataInizio) - moment(b.dataInizio);
    });

    // setUserBookings(futureOrders);
    // group bookings by same date
    setUserBookings(Object.values(
      futureOrders.reduce((acc, obj) => ({ ...acc, [obj.dataInizio && obj.dataFine]: obj }), {})
    ));
    
    update("userBookings", futureOrders);
    setReady(true);

    setTimeout(() => {
      try {
        if (programmate) {
          document
            .querySelector(".elenco_soste-prog")
            .classList.remove("loading");
        } else {
          document
            .querySelector(".elenco_soste-effet")
            .classList.remove("loading");
        }
      } catch (error) {}
    }, 50);
  };

  useEffect(() => {
    getUserBookings();
  }, []);

  return {
    userBookings,
    ready,
  };
};
